<template>
  <div class="ecCond-tree" v-if="dataTree && dataTree.length > 0">
    <section
      class="ecCond-tree__each"
      v-for="(item, index) in dataTree"
      :key="'crowd0' + index"
      v-show="item.list && item.list.length > 0"
    >
      <div class="ecCond-article">
        <div
          class="ecCond-article__left"
          :class="{
            andSelected: item.type === 'and',
          }"
          v-if="item.list && item.list.length > 1"
          @click="changeType(item.menuIndex)"
        >
          <span class="ecCond-article__andWord">{{
            $t("crowd.set_crowdBag_and")
          }}</span>

          <span class="ecCond-article__orWord">{{
            $t("crowd.set_crowdBag_or")
          }}</span>
        </div>

        <div class="ecCond-article__right">
          <section class="ecCond-article__right__article">
            <!-- last list -->
            <section
              v-for="(itemIn, indexIn) in item.list"
              :key="'crowdTreeItemin' + indexIn"
              class="ecCond-lineOut"
              :style="{ 'z-index': item.list.length - indexIn }"
            >
              <div class="ecCond-tree">
                <div
                  class="ecCond-article"
                  v-if="itemIn.list && itemIn.list.length > 0"
                >
                  <div
                    class="ecCond-article__left"
                    :class="{
                      andSelected: itemIn.type === 'and',
                    }"
                    v-if="itemIn.list && itemIn.list.length > 1"
                    @click="changeType(itemIn.menuIndex)"
                  >
                    <span class="ecCond-article__andWord">{{
                      $t("crowd.set_crowdBag_and")
                    }}</span>

                    <span class="ecCond-article__orWord">{{
                      $t("crowd.set_crowdBag_or")
                    }}</span>
                  </div>

                  <div class="ecCond-article__right">
                    <div
                      class="ecCond-line"
                      v-for="(itemLast, indexLast) in itemIn.list"
                      :key="'crowdLast' + indexLast"
                    >
                      <front-drop-down
                        :activeValue="itemLast.value1"
                        :activeName="itemLast.value1Name"
                        class="ecCond-line__fdd"
                      >
                        <div slot="content">
                          <select-cd-tree
                            :dataList="cond1List"
                            :indexInfo="[index, indexIn, indexLast]"
                            dataKey="value1"
                            dataNameKey="value1Name"
                            @change="cond1ChangeFn"
                            v-if="!subLoading"
                          />
                        </div>
                      </front-drop-down>

                      <front-drop-down
                        :activeValue="itemLast.value2"
                        :activeName="itemLast.value2Name"
                        class="ecCond-line__fdd"
                      >
                        <div slot="content">
                          <select-cd-list
                            :dataList="itemLast.value2List"
                            :dataValue="itemLast.value2"
                            :indexInfo="[index, indexIn, indexLast]"
                            dataType="one"
                            dataKey="value2"
                            dataNameKey="value2Name"
                            @select="selectFn"
                          />
                        </div>
                      </front-drop-down>

                      <front-drop-down
                        :activeValue="itemLast.value3"
                        :activeName="itemLast.value3Name"
                        :dataType="itemLast.dataType"
                        :indexInfo="[index, indexIn, indexLast]"
                        @delete="lastDeleteFn"
                        class="ecCond-line__fdd"
                        v-if="
                          itemLast.dataType === 'array' ||
                          itemLast.dataType === 'arraySingle'
                        "
                      >
                        <div slot="content">
                          <select-cd-list
                            :dataList="itemLast.value3List"
                            :dataValue="itemLast.value3"
                            :indexInfo="[index, indexIn, indexLast]"
                            :dataType="itemLast.dataType"
                            dataKey="value3"
                            dataNameKey="value3Name"
                            @select="selectFn"
                          />
                        </div>
                      </front-drop-down>

                      <div
                        class="ecCond-line__fdd"
                        v-else-if="itemLast.dataType === 'date'"
                      >
                        <el-date-picker
                          v-model="itemLast.value3"
                          type="daterange"
                          range-separator="-"
                          value-format="yyyy-MM-dd"
                          :start-placeholder="$t('crowd.startDate')"
                          :end-placeholder="$t('crowd.endDate')"
                        >
                        </el-date-picker>
                      </div>

                      <div class="ecCond-line__text ecCond-line__fdd" v-else>
                        <input
                          type="text"
                          :placeholder="$t('home.selectPlace')"
                          v-model="itemLast.value3"
                        />
                      </div>

                      <span
                        class="ecCond-line__delete"
                        @click="deleteCond(index, indexIn, indexLast)"
                      ></span>
                    </div>

                    <div
                      class="ecCond-tree__bottom"
                      :class="{
                        'ecCond-lastOne':
                          !(item.list && item.list.length > 1) &&
                          !(itemIn.list && itemIn.list.length > 1),
                      }"
                    >
                      <span
                        class="ecCond-tree__bottom__btn"
                        @click="addCond(index, indexIn)"
                        >+ {{ $t("crowd.set_crowdBag_tagAddWord") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- last list end -->
          </section>
        </div>
      </div>

      <div class="ecCond-tree__bottom">
        <span class="ecCond-tree__bottom__btn" @click="addCond(index)"
          >+ {{ $t("crowd.set_crowdBag_tagAddWord") }}</span
        >
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import SelectCdList from "./selectCdList.vue";
import selectCdTree from "./selectCdTree.vue";

export default {
  components: { selectCdTree, SelectCdList },
  name: "crowdTree2",
  props: ["dataTree", "cond1List"],
  data() {
    return {
      subLoading: true,
    };
  },
  methods: {
    changeType(data) {
      this.$emit("changeType", data);
    },
    changeTypeFn(dataindex) {
      this.changeType(dataindex);
    },
    addCond(index, indexIn) {
      let params = {
        index: index,
        indexIn: indexIn,
      };

      this.$emit("add", params);
    },
    deleteCond(index, indexIn, indexLast) {
      let params = {
        index: index,
        indexIn: indexIn,
        indexLast: indexLast,
      };

      this.$emit("delete", params);
    },
    selectFn(data) {
      this.$emit("select", data);
    },
    cond1ChangeFn(data) {
      this.$emit("condTreeChange", data);
    },
    lastDeleteFn(data) {
      this.$emit("deleteCondLst", data);
    },
  },
  async mounted() {
    this.subLoading = false;
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.ecCond-tree {
  width: 100%;

  .ecCond-tree__each {
    width: 100%;
  }
  .ecCond-tree__bottom {
    width: 100%;

    &.ecCond-lastOne {
      height: 0;
      overflow: hidden;
      margin-bottom: toPad(-25);
    }
  }
  .ecCond-tree__bottom__btn {
    display: inline-flex;
    height: toPad(30);
    padding: 0 toPad(15);
    align-items: center;
    margin-right: toPad(10);
    cursor: pointer;
    background: #f6f8fa;
    border-radius: toPad(4);
    font-size: toPad(16);
  }
}
.ecCond-article {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: toPad(25);

  .ecCond-article__left {
    position: relative;
    width: toPad(24);
    flex-shrink: 0;
    margin-right: toPad(10);

    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 0;
      width: toPad(3);
      height: 100%;
      overflow: hidden;
      background: #037849;
      transform: translateX(-50%);
    }

    .ecCond-article__andWord {
      display: inline-block;
      position: absolute;
      font-size: toPad(15);
      left: 0;
      top: 50%;
      transform: translateY(-36%);
      color: #3073f2;
      border: 1px solid #3073f2;
      padding: 0 toPad(2);
      z-index: 1;
      cursor: pointer;
      line-height: toPad(21);
    }
    .ecCond-article__orWord {
      display: inline-block;
      position: absolute;
      font-size: toPad(15);
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 toPad(2);
      z-index: 2;
      background: #037849;
      color: #fff;
      cursor: pointer;
      line-height: toPad(21);
      border: 1px solid #037849;
    }

    &.andSelected {
      &::after {
        background: #3073f2;
      }
      .ecCond-article__andWord {
        z-index: 2;
        background: #3073f2;
        color: #fff;
        transform: translateY(-50%);
        left: auto;
        right: 0;
      }
      .ecCond-article__orWord {
        z-index: 1;
        background: #fff;
        color: #037849;
        transform: translateY(-36%);
        left: 0;
        right: auto;
      }
    }
  }
  .ecCond-article__right {
    flex-basis: 100%;
  }

  .ecCond-lineOut {
    position: relative;
    width: 100%;
    z-index: 5;

    .ecCond-line {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: toPad(20);
    }

    &:last-child {
      .ecCond-article {
        margin-bottom: 0;
      }
    }
  }
}

.ecCond-line__text {
  position: relative;
  width: 100%;
  height: toPad(38);
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: toPad(6);
  display: flex;
  align-items: center;
  padding: 0 toPad(40) 0 toPad(20);

  input {
    width: 100%;
    background: transparent;
    border: 0;
    font-size: toPad(15);

    &::placeholder {
      font-size: toPad(15);
      color: #bbb;
    }
  }
}
.ecCond-line__fdd {
  margin-right: toPad(10);

  &:nth-of-type(1) {
    width: toPad(200);
  }
  &:nth-of-type(2) {
    width: toPad(100);
  }
  &:nth-of-type(3) {
    width: toPad(260);
    margin-right: toPad(10);
  }
  .el-range-editor.el-input__inner {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: toPad(6);
    height: toPad(38);

    input {
      color: #000;
      font-size: toPad(15);
    }
  }
}
.ecCond-line__delete {
  width: toPad(20);
  height: toPad(20);
  overflow: hidden;
  cursor: pointer;
  background: url("~@/src/assets/crowd/doc-del.png") no-repeat center center;
  background-size: auto 100%;
}
</style>
