<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>
          <section class="edp-backend-conditionSearch">
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.dtableTitle1") }}
              </div>
              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  :placeholder="$t('edpBackendCommon.placeholder')"
                  v-model="query.medalName"
                  clearable
                />
              </div>
            </div>
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.tableTite5") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-select
                  v-model="query.isEnabled"
                  clearable
                  :placeholder="$t('edpBackendCommon.select')"
                >
                  <el-option
                    :label="$t('edpBackendCommon.statusNp')"
                    :value="false"
                  ></el-option>
                  <el-option
                    :label="$t('edpBackendCommon.statusYes')"
                    :value="true"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label"></div>

              <div class="edp-backend-conditionSearch-content"></div>
            </div>
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.tableTite6") }}
              </div>

              <div>
                <el-date-picker
                  v-model="times"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetimerange"
                  range-separator="-"
                  :start-placeholder="$t('edpBackendCommon.startDate')"
                  :end-placeholder="$t('edpBackendCommon.endDate')"
                >
                </el-date-picker>
              </div>
            </div>
            <div
              class="edp-backend-conditionSearch-square conditionSearch-square-last"
            >
              <el-button size="small" @click="onclear">{{
                $t("edpBackendCommon.clear")
              }}</el-button>
              <el-button size="small" type="primary" @click="getList(1)">{{
                $t("edpBackendCommon.search")
              }}</el-button>
            </div>
          </section>
          <section class="edp-backend-common-table">
            <div class="table-headControlLine">
              <div>
                <el-button type="primary" size="small" @click="onShow(1)">
                  {{ $t("edpBackendCommon.add") }}</el-button
                >
              </div>
            </div>

            <el-table
              stripe
              :data="pageList"
              style="width: 100%"
              v-loading="loading"
            >
              <el-table-column :label="$t('edpBackendEntry.mtableTitle1')">
                <template slot-scope="scope">
                  <img
                    :src="scope.row.medalIconHost + scope.row.medalIconUrl"
                    width="50"
                    height="50"
                  />
                </template>
              </el-table-column>

              <el-table-column
                prop="medalCnName"
                :label="$t('edpBackendEntry.formTit1')"
              >
              </el-table-column>

              <el-table-column
                prop="medalEnName"
                :label="$t('edpBackendEntry.tableTite2')"
              >
              </el-table-column>

              <el-table-column
                prop="isEnabled"
                :label="$t('edpBackendEntry.tableTite5')"
              >
                <template slot-scope="scope">
                  <el-tag
                    :type="scope.row.isEnabled ? 'success' : 'danger'"
                    disable-transitions
                    >{{
                      scope.row.isEnabled
                        ? $t("edpBackendCommon.statusYes")
                        : $t("edpBackendCommon.statusNp")
                    }}</el-tag
                  >
                </template>
              </el-table-column>

              <el-table-column
                prop="createTime"
                :label="$t('edpBackendEntry.tableTite6')"
              ></el-table-column>

              <el-table-column
                :label="$t('edpBackendCommon.operate')"
                fixed="right"
                width="110"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="onShow(2, scope.row)">
                    {{ $t("edpBackendCommon.edit") }}</el-button
                  >
                  <el-button type="text" @click="deleteRow(scope.row)">
                    {{ $t("edpBackendCommon.delete") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="pageTotal"
            >
            </el-pagination>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>
    <el-dialog
      :title="dialogTitle"
      width="65%"
      :visible.sync="editShow"
      :before-close="onClose"
    >
      <el-form :model="form" label-width="140px" ref="funForm">
        <el-form-item
          prop="medalCnName"
          :label="$t('edpBackendEntry.formTit1')"
          :rules="[
            { required: true, message: $t('edpBackendCommon.notEmpty') },
          ]"
        >
          <el-input
            v-model="form.medalCnName"
            autocomplete="off"
            :placeholder="$t('edpBackendCommon.placeholder')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('edpBackendEntry.tableTite2')">
          <el-input
            v-model="form.medalEnName"
            autocomplete="off"
            :placeholder="$t('edpBackendCommon.placeholder')"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="medalIconUrl"
          :label="$t('edpBackendEntry.formTit2')"
          :rules="[
            { required: true, message: $t('edpBackendCommon.notEmpty') },
          ]"
        >
          <UploadImage
            :limit="1"
            :imgList="fileImgList"
            @onSuccessFiles="onSuccessImgFiles"
          />
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.mtableTitle2')"
          prop="medalRuleType"
          :rules="[
            { required: true, message: $t('edpBackendCommon.notEmpty') },
          ]"
        >
          <el-radio-group v-model="form.medalRuleType">
            <el-radio :label="1">{{
              $t("edpBackendEntry.mtableTitle7")
            }}</el-radio>
            <el-radio :label="2">{{
              $t("edpBackendEntry.mtableTitle8")
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.medalRuleType == 1">
          <el-form-item :label="$t('edpBackendEntry.mtableTitle4')">
            <crowd-tree
              :cond1List="cond1List"
              :dataTree="dataTree"
              @changeType="changeTypeFn"
              @add="dataTreeAdd"
              @delete="dataTreeDelete"
              @select="selectFn"
              @condTreeChange="cond1Change"
              @deleteCondLst="cond3Delete"
            />
          </el-form-item>
          <el-form-item :label="$t('edpBackendEntry.mtableTitle5')">
            <el-input
              v-model="form.ruleTopNumber"
              autocomplete="off"
              :placeholder="$t('edpBackendCommon.placeholder')"
            ></el-input>
          </el-form-item>
        </template>
        <template v-if="form.medalRuleType == 2">
          <el-form-item :label="$t('edpBackendEntry.mtableTitle3')">
            <el-select
              v-model="form.packId"
              :placeholder="$t('edpBackendCommon.placeholder')"
              clearable
            >
              <el-option
                v-for="(item, i) in BagList"
                :key="i"
                :label="item.packCnName"
                :value="item.id"
              ></el-option>
            </el-select>
            &nbsp;<el-button
              type="primary"
              :disabled="!form.packId"
              @click="(form.medalRuleType = 1), getBagDetail(form.packId)"
              >{{ $t("edpBackendCommon.import") }}</el-button
            >
          </el-form-item>
        </template>
        <el-form-item :label="$t('edpBackendEntry.dtableTitle8')">
          <el-switch
            v-model="form.isEnabled"
            active-color="rgba(97, 191, 180, 1)"
            inactive-color="rgba(0, 0, 0, 0.24705882352941178)"
          >
          </el-switch>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="onClosed">{{
          $t("edpBackendCommon.canale")
        }}</el-button>
        <el-button type="primary" @click="onSave('funForm')">{{
          $t("edpBackendCommon.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import UploadImage from "../../components/upload/entryUpload.vue";
import crowdTree from "../../components/crowd/crowdTree2.vue";
export default {
  name: "backendFunction",
  components: {
    UploadImage,
    crowdTree,
  },
  data() {
    return {
      query: {
        medalName: "",
        isEnabled: "",
        createTimeStart: "",
        createTimeEnd: "",
      },
      pageList: [],
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      loading: false,
      editShow: false,
      dialogTitle: this.$t("edpBackendCommon.add"),
      fileImgList: [],
      form: {
        id: "",
        medalCnName: "",
        medalEnName: "",
        medalIconUrl: "",
        medalRuleType: 1,
        ruleWhereJson: {
          // join: "",
          // conditionGroup: [
          //   {
          //     join: "",
          //     fieldGroup: [
          //       {
          //         fieldId: 0,
          //         operateSymbol: "",
          //         value: "",
          //       },
          //     ],
          //   },
          // ],
        },
        ruleJoinTable: "",
        ruleWhereSql: "",
        ruleTopNumber: null,
        ruleSortMethod: "",
        packId: null,
        isEnabled: false,
      },
      times: ["", ""],
      types: [],
      methods: [
        { name: "模板一", value: "1" },
        { name: "模板二", value: "2" },
      ],
      dataTreeItem: {
        value1: "",
        value2: "",
        value3: "",
        value2List: [],
        value3List: [],
      },
      dataTree: [
        {
          type: "or",
          list: [
            {
              type: "or",
              list: [
                {
                  value1: "",
                  value2: "",
                  value3: "",
                  value2List: [],
                  value3List: [],
                },
              ],
            },
          ],
        },
      ],
      cond1List: [],
      BagList: [],
      treeShow: false,
    };
  },
  watch: {
    times(val) {
      console.log(val);
      if (val) {
        this.query.createTimeStart = val[0];
        this.query.createTimeEnd = val[1];
      }
    },
  },
  computed: {
    condNum() {
      let result = 0;

      this.dataTree[0].list.forEach((val) => {
        val.list.forEach((val2) => {
          result += 1;
        });
      });

      return result;
    },
  },
  async mounted() {
    this.getMyBag();
    this.getList();
    let _this = this;

    let resCond1 = await this.bagCond1();

    this.cond1List =
      resCond1.data && resCond1.data.data ? resCond1.data.data : [];
    this.dataTree.forEach((val, e) => {
      this.$set(val, "menuIndex", e);
    });
    this.$nextTick(() => {
      this.initTree(this.dataTree);

      this.$forceUpdate();
    });
  },
  methods: {
    ...mapActions({
      medalList: "user/medalList",
      medalDetail: "user/medalDetail",
      addMedal: "user/addMedal",
      editMedal: "user/editMedal",
      delMedal: "user/delMedal",
      bagCond1: "crowd/bagCond1",
      myBagList: "crowd/myBagList",
      myBagDetail: "crowd/myBagDetail",
      fieldValueOptionUri: "crowd/fieldValueOptionUri",
    }),
    initTree(data) {
      data.forEach((val, e) => {
        if (val.list && val.list.length > 0) {
          val.list.forEach((val2, e2) => {
            val2.menuIndex = val.menuIndex + "-" + e2;
          });
          this.initTree(val.list);
        }
      });
    },
    aryEach(data, dataindex) {
      data.forEach((val, e) => {
        if (val.menuIndex == dataindex) {
          val.type === "and" ? (val.type = "or") : (val.type = "and");
        }

        if (val.list && val.list.length > 0) {
          this.aryEach(val.list, dataindex);
        }
      });

      this.$forceUpdate();
    },
    changeTypeFn(dataindex) {
      this.aryEach(this.dataTree, dataindex);
    },
    dataTreeAdd(data) {
      let itemLs = JSON.parse(JSON.stringify(this.dataTreeItem));

      if (data.indexIn || data.indexIn == 0) {
        this.dataTree[data.index].list[data.indexIn].list.push(itemLs);
      } else {
        this.dataTree[data.index].list.push({ type: "or", list: [itemLs] });
      }

      this.initTree(this.dataTree);
    },
    dataTreeDelete(data) {
      if (this.condNum > 1) {
        this.dataTree[data.index].list[data.indexIn].list.splice(
          data.indexLast,
          1
        );

        if (this.dataTree[data.index].list[data.indexIn].list.length == 0) {
          this.dataTree[data.index].list.splice(data.indexIn, 1);
        }
      }
    },
    selectFn(data) {
      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        [data.dataKey],
        data.data
      );

      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        [data.dataNameKey],
        data.dataName
      );

      if (!data.dataType || data.dataType != "array") {
        this.$store.commit("login/set_dropDownTotalStatus", false);
      }
    },
    cond1Change(data) {
      // 条件1选择
      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        [data.dataKey],
        data.data
      );

      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        [data.dataNameKey],
        data.dataName
      );

      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        "fieldId",
        data.fieldId
      );

      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        "value2List",
        data.cond2queryData
      );

      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        "dataType",
        data.fieldType
      );

      this.$set(
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ],
        "value3List",
        data.cond3queryData
      );

      if (data.dataKey === "value1") {
        // 重置后两个数据
        this.$set(
          this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
            data.indexInfo[2]
          ],
          "value2",
          ""
        );

        this.$set(
          this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
            data.indexInfo[2]
          ],
          "value2Name",
          ""
        );

        this.$set(
          this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
            data.indexInfo[2]
          ],
          "value3",
          ""
        );

        this.$set(
          this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
            data.indexInfo[2]
          ],
          "value3Name",
          ""
        );
      }

      this.$store.commit("login/set_dropDownTotalStatus", false);

      this.$forceUpdate();
    },
    cond3Delete(data) {
      let lastValueLs =
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ].value3;

      let lastValueNameLs =
        this.dataTree[data.indexInfo[0]].list[data.indexInfo[1]].list[
          data.indexInfo[2]
        ].value3Name;

      lastValueLs.some((val, e) => {
        if (val === data.data) {
          lastValueLs.splice(e, 1);
          lastValueNameLs.splice(e, 1);
        }
      });
    },
    checkDateTree(data) {
      let result = true;

      data.some((val) => {
        val.list.some((val2) => {
          val2.list.some((val3) => {
            if (!val3.value1 || !val3.value2 || !val3.value3) {
              this.$message({
                type: "warning",
                message: this.$t("crowd.treeEmpty"),
              });
              result = false;
              return true;
            }
          });
        });
      });

      return result;
    },
    async getBagDetail(val) {
      let params = {
        id: val,
      };

      let res = await this.myBagDetail(params);
      let resData = res.data.data;
      this.bagInit(resData.whereJson);
    },
    bagInit(resData) {
      console.log(resData);
      this.dataTree = [{ type: resData.join, list: [] }];

      resData.conditionGroup.forEach((val, e) => {
        this.dataTree[0].list.push({ type: val.join, list: [] });
      });

      resData.conditionGroup.forEach((val, e) => {
        val.fieldGroup.forEach((val2, e2) => {
          this.dataTree[0].list[e].list.push({
            fieldId: val2.fieldId,
            operateSymbol: val2.operateSymbol,
            value: val2.value,
            value1: val2.fieldId,
            value1Name: "",
            value2: val2.operateSymbol,
            value2Name: "",
            value2List: [],
            value3: val2.value,
            value3Name: "",
            value3List: [],
          });
        });
      });

      let _this = this;

      function treeInit(data) {
        data.forEach((valOut, eOut) => {
          if (valOut.queryFieldDtos && valOut.queryFieldDtos.length > 0) {
            valOut.queryFieldDtos.forEach((val, e) => {
              _this.dataTree[0].list.forEach((valDataPage, eDataPage) => {
                valDataPage.list.forEach(async (valDataIn, eDataIn) => {
                  if (valDataIn.fieldId == val.id) {
                    valDataIn.value1Name = _this.utils.formatLang(
                      _this.$i18n.locale,
                      val.fieldCommentCnName,
                      val.fieldCommentEnName
                    );

                    let list2Ls = JSON.parse(JSON.stringify(val.operateSymbol));

                    list2Ls.forEach((valLs) => {
                      valLs.cName = valLs.labelCnName;
                      valLs.eName = valLs.labelEnName;

                      if (valLs.value === valDataIn.value2) {
                        valDataIn.value2Name = _this.utils.formatLang(
                          _this.$i18n.locale,
                          valLs.labelCnName,
                          valLs.labelEnName
                        );
                      }
                    });

                    valDataIn.value2List = list2Ls;

                    if (val.fieldType === "array") {
                      valDataIn.value3Name = [];
                    } else {
                      valDataIn.value3Name = "";
                    }

                    if (val.fieldValueType === "remote") {
                      valDataIn.dataType =
                        val.fieldType === "number"
                          ? "arraySingle"
                          : val.fieldType;

                      let urlLs =
                        process.env.NODE_ENV === "development"
                          ? "api" + val.fieldValueOptionUri
                          : val.fieldValueOptionUri;

                      let dataParams = {
                        url: urlLs,

                        params: {
                          fieldId: val.id,
                        },
                      };

                      let resData3 = await _this.fieldValueOptionUri(
                        dataParams
                      );

                      let list3Ls = resData3.data.data || [];

                      list3Ls.forEach((valLs) => {
                        valLs.cName = valLs.labelCnName;
                        valLs.eName = valLs.labelEnName;

                        if (val.fieldType === "array") {
                          if (valDataIn.value3.includes(valLs.value)) {
                            valDataIn.value3Name.push(
                              _this.utils.formatLang(
                                _this.$i18n.locale,
                                valLs.labelCnName,
                                valLs.labelEnName
                              )
                            );
                          }
                        } else {
                          if (valLs.value === valDataIn.value3) {
                            valDataIn.value3Name = _this.utils.formatLang(
                              _this.$i18n.locale,
                              valLs.labelCnName,
                              valLs.labelEnName
                            );
                          }
                        }
                      });

                      valDataIn.value3List = list3Ls || [];
                    } else {
                      let list3Ls = val.fieldValueOption
                        ? JSON.parse(JSON.stringify(val.fieldValueOption))
                        : [];

                      list3Ls.forEach((valLs) => {
                        valLs.cName = valLs.labelCnName;
                        valLs.eName = valLs.labelEnName;

                        if (val.fieldType === "array") {
                          if (valDataIn.value3.includes(valLs.value)) {
                            valDataIn.value3Name.push(
                              _this.utils.formatLang(
                                _this.$i18n.locale,
                                valLs.labelCnName,
                                valLs.labelEnName
                              )
                            );
                          }
                        } else {
                          if (valLs.value === valDataIn.value3) {
                            valDataIn.value3Name = _this.utils.formatLang(
                              _this.$i18n.locale,
                              valLs.labelCnName,
                              valLs.labelEnName
                            );
                          }
                        }
                      });

                      valDataIn.value3List = list3Ls || [];

                      valDataIn.dataType = val.fieldType;
                    }
                  }
                });
              });
            });
          } else {
            if (valOut.children && valOut.children.length > 0) {
              treeInit(valOut.children);
            }
          }
        });
      }

      treeInit(this.cond1List);

      this.dataTree[0].list.forEach((val, e) => {
        val.list.forEach((val2, e2) => {
          if (val2.dataType === "array" || val2.dataType === "date") {
            typeof val2.value === "object"
              ? (val2.value3 = val2.value)
              : (val2.value3 = val2.value.toString().split(","));
          } else {
            val2.value3 = val2.value;
          }
        });
      });

      this.initTree(this.dataTree);
    },
    // 监听图片上传
    onSuccessImgFiles(files) {
      console.log("onSuccessImgFiles", files);
      this.fileImgList = files;
      if (this.fileImgList.length) {
        this.form.medalIconUrl = files[0].name;
      }
    },
    async getMyBag() {
      let res = await this.myBagList({ current: 1, size: 1000 });

      this.BagList =
        res.data && res.data.data && res.data.data.records
          ? res.data.data.records
          : [];
    },
    async getList(val) {
      if (val) {
        this.currentPage = val;
      }
      let params = {
        current: this.currentPage,
        size: this.pageSize,
        ...this.query,
      };
      let res = await this.medalList(params);
      this.pageList = res.data.records;
      this.currentPage = res.data.current;
      this.pageTotal = res.data.total;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    onclear() {
      this.query = {
        medalName: "",
        createTimeStart: "",
        createTimeEnd: "",
        isEnabled: "",
      };
      this.times = ["", ""];
    },
    onClose(done) {
      for (var key in this.form) {
        if (key === "isDefault" || key === "isEnabled") {
          this.form[key] = false;
        } else {
          this.form[key] = "";
        }
      }
      done();
    },
    async getMedalDetail(val) {
      let res = await this.medalDetail(val);
      this.form = res.data;
      let urls = this.form.medalIconHost + this.form.medalIconUrl;
      this.fileImgList = [
        {
          uid: this.form.medalIconUrl,
          url: urls,
          name: this.form.medalIconUrl,
        },
      ];
      this.bagInit(res.data.ruleWhereJson);
    },
    onShow(val, data) {
      if (val == 1) {
        this.dialogTitle = this.$t("edpBackendCommon.add");
        this.fileImgList = [];
      } else {
        console.log(data);
        this.dialogTitle = this.$t("edpBackendCommon.edit");
        this.getMedalDetail(data.id);
      }
      this.editShow = true;
    },
    onClosed() {
      this.editShow = false;
      for (var key in this.form) {
        if (key === "isEnabled") {
          this.form[key] = false;
        } else {
          this.form[key] = null;
        }
      }
      this.dataTree = [
        {
          type: "or",
          list: [
            {
              type: "or",
              list: [
                {
                  value1: "",
                  value2: "",
                  value3: "",
                  value2List: [],
                  value3List: [],
                },
              ],
            },
          ],
        },
      ];
    },
    async onSave(formName) {
      let res = {};
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.form.ruleWhereJson = {
            join: this.dataTree[0].type,
            conditionGroup: [],
          };
          this.dataTree[0].list.forEach((val) => {
            this.form.ruleWhereJson.conditionGroup.push({
              join: val.type,
              fieldGroup: [],
            });
          });

          this.dataTree[0].list.forEach((val, e) => {
            val.list.forEach((valLastList, eLastList) => {
              this.form.ruleWhereJson.conditionGroup.forEach((val2, e2) => {
                if (e == e2) {
                  val2.fieldGroup.push({
                    value:
                      typeof valLastList.value3 === "object"
                        ? valLastList.value3.join(",")
                        : valLastList.value3,

                    operateSymbol: valLastList.value2,

                    fieldId: valLastList.fieldId,
                  });
                }
              });
            });
          });
          if (this.form.id) {
            res = await this.editMedal(this.form);
          } else {
            res = await this.addMedal(this.form);
          }

          if (res.success) {
            this.onClosed();
            this.getList();
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deleteRow(data) {
      this.$confirm(
        this.$t("edpBackendCommon.actionConfirm"),
        this.$t("edpBackendCommon.noticeTitle"),
        {
          confirmButtonText: this.$t("edpBackendCommon.define"),
          cancelButtonText: this.$t("edpBackendCommon.canale"),
        }
      ).then(async () => {
        let res = await this.delMedal(data.id);

        if (res.data.success) {
          this.$message({
            type: "success",
            message: this.$t("edpBackendCommon.actionSuccess"),
          });

          this.getList();
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
</style>
